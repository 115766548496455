import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB7o_X6Ho0iXYubhhUt5zWuZ_gjntiSlnQ",
    authDomain: "shwtym-63268.firebaseapp.com",
    projectId: "shwtym-63268",
    storageBucket: "shwtym-63268.appspot.com",
    messagingSenderId: "940511484905",
    appId: "1:940511484905:web:b847f6a025c8bc5bdc0978",
    measurementId: "G-EGDTDPGVDR"
  };

  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const db = getFirestore(app);
  export const storage = getStorage(app);
  
  export default app;