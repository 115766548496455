import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Typography, Box, Grid, Avatar, TextField, Link, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { AuthContext } from '../Auth/AuthContext';

const USERS_PER_PAGE = 20;

const PeoplePage = () => {
  const [users, setUsers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { user: currentUser } = useContext(AuthContext);

  const fetchUsers = useCallback(async (loadMore = false) => {
    if (loading || (!loadMore && users.length > 0)) return;

    setLoading(true);
    const usersRef = collection(db, 'users');
    let q = query(usersRef, orderBy('username'), limit(USERS_PER_PAGE));

    if (loadMore && lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    try {
      const querySnapshot = await getDocs(q);
      const fetchedUsers = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(user => user.id !== currentUser.uid);

      setUsers(prevUsers => loadMore ? [...prevUsers, ...fetchedUsers] : fetchedUsers);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === USERS_PER_PAGE);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [loading, users.length, lastVisible, currentUser.uid]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const filteredUsers = users.filter(user => 
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', pt: 4 }}>
      <Typography variant="h4" gutterBottom>
        People on Shwtym
      </Typography>
      <TextField
        fullWidth
        label="Search users"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={2}>
        {filteredUsers.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.id}>
            <Link 
              component={RouterLink} 
              to={`/profile/${user.id}`} 
              underline="none" 
              color="inherit"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', p: 2, border: '1px solid #ddd', borderRadius: '4px' }}>
                <Avatar
                  src={user.profilePicUrl}
                  sx={{ width: 50, height: 50, mr: 2 }}
                />
                <Typography variant="subtitle1">
                  {user.username}
                </Typography>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
      {hasMore && (
        <Button onClick={() => fetchUsers(true)} disabled={loading}>
          {loading ? 'Loading...' : 'Load More'}
        </Button>
      )}
    </Box>
  );
};

export default PeoplePage;