import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';

import theme from './theme';
import { AuthProvider, AuthContext } from './components/Auth/AuthContext';
import WelcomePage from './components/Welcome/WelcomePage';
import SignUp from './components/Auth/SignUp';
import ExplorePage from './components/Explore/ExplorePage';
import NowPlayingPage from './components/NowPlaying/NowPlayingPage';
import ProfilePage from './components/Profile/ProfilePage';
import EditProfilePage from './components/Profile/EditProfilePage';
import FeedPage from './components/Feed/FeedPage';
import PeoplePage from './components/People/PeoplePage';
import FloatingActionButton from './components/Common/FloatingActionButton';
import NewPostDialog from './components/Common/NewPostDialog';

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  return user ? children : <Navigate to="/" replace />;
};

function AppContent() {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openNewPost, setOpenNewPost] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      console.log("User signed out successfully");
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleNewPost = () => {
    setOpenNewPost(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {user && (
        <>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Shwtym
              </Typography>
              <Button color="inherit" onClick={() => navigate('/explore')}>Explore</Button>
              <Button color="inherit" onClick={() => navigate('/now-playing')}>Now Playing</Button>
              <Button color="inherit" onClick={() => navigate('/feed')}>Feed</Button>
              <Button color="inherit" onClick={() => navigate('/people')}>People</Button>
              <Button color="inherit" onClick={() => navigate('/profile')}>Profile</Button>
              <Button color="inherit" onClick={handleLogout}>Logout</Button>
            </Toolbar>
          </AppBar>
          <FloatingActionButton onClick={handleNewPost} />
          <NewPostDialog open={openNewPost} onClose={() => setOpenNewPost(false)} />
        </>
      )}
      <Box sx={{ padding: user ? 3 : 0 }}>
        <Routes>
          <Route path="/" element={user ? <Navigate to="/explore" replace /> : <WelcomePage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/explore" element={<ProtectedRoute><ExplorePage /></ProtectedRoute>} />
          <Route path="/now-playing" element={<ProtectedRoute><NowPlayingPage /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="/profile/:userId" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="/edit-profile" element={<ProtectedRoute><EditProfilePage /></ProtectedRoute>} />
          <Route path="/feed" element={<ProtectedRoute><FeedPage /></ProtectedRoute>} />
          <Route path="/people" element={<ProtectedRoute><PeoplePage /></ProtectedRoute>} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;