import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import backgroundVideo from '../../assets/background-video.mp4';
import './WelcomePage.css';

const WelcomePage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);
  const [isSignIn, setIsSignIn] = useState(true);
  const navigate = useNavigate();
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      video.addEventListener('loadedmetadata', () => {
        const duration = video.duration;
        const randomStart = Math.floor(Math.random() * (duration - 40)) + 10;
        video.currentTime = randomStart;
      });
    }
  }, []);

  const checkUsernameAvailability = async (username) => {
    console.log('Checking Username availability:', username);
    try {
      const q = query(collection(db, 'users'), where('username', '==', username));
      const querySnapshot = await getDocs(q);
      console.log('Query snapshot:', querySnapshot);
      return querySnapshot.empty;
    } catch (error) {
      console.error('Error checking Username availability:', error);
      throw error;
    }
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      if (isSignIn) {
        // Check if input is email or username
        const isEmail = /\S+@\S+\.\S+/.test(email);
        console.log('Attempting to sign in with:', isEmail ? 'email' : 'username');
        let loginEmail = email;
        
        if (!isEmail) {
          console.log('Input is not an email, fetching associated email');
          // If not an email, fetch the email associated with the username
          const q = query(collection(db, 'users'), where('username', '==', email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            loginEmail = querySnapshot.docs[0].data().email;
            console.log('Found associated email:', loginEmail);
          } else {
            throw new Error('Username not found');
          }
        }
        
        console.log('Signing in with email:', loginEmail);
        await signInWithEmailAndPassword(auth, loginEmail, password);
        console.log('Sign in successful');
        navigate('/explore');
      } else {
        console.log('Starting signup process');

        // Check if username is available
        try {
          const isAvailable = await checkUsernameAvailability(username);
          if (!isAvailable) {
            setError('Username is already taken. Please choose a different one.');
            return;
          }
          console.log('Username is available');
        } catch (error) {
          console.error('Error during Username check:', error);
          setError('Unable to check Username availability. Please try again.');
          return;
        }

        // Register new user
        console.log('Registering new user with email:', email);
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        console.log('User registered in Auth:', userCredential.user.uid);

        // Save user info to Firestore
        console.log('Saving user info to Firestore');
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          email: email,
          username: username
        });
        console.log('User info saved to Firestore');

        // Send email verification
        console.log('Sending email verification');
        await sendEmailVerification(userCredential.user);
        console.log('Email verification sent');

        alert('Registration successful! Please check your email for a verification link.');
        navigate('/explore');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setError(`An error occurred: ${error.message}. Please try again.`);
    }
  };

  const toggleAuthMode = () => {
    setIsSignIn(!isSignIn);
    setError(null);
  };

  return (
    <div className="welcome-container">
      <video ref={videoRef} autoPlay muted loop playsInline id="bg-video">
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay"></div>
      <div className="header">
        <Typography variant="h1" className="title">
          Shwtym
        </Typography>
        <Typography variant="h5" className="tagline">
          Discover, Share, and Celebrate Cinema
        </Typography>
      </div>
      <Box className="content">
        <Box component="form" onSubmit={handleAuth} className="auth-form">
          {!isSignIn && (
            <>
              <Typography className="input-label">Username</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </>
          )}
          <Typography className="input-label">{isSignIn ? 'Email or Username' : 'Email'}</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography className="input-label">Password</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="auth-button"
          >
            {isSignIn ? 'START YOUR CINEMATIC JOURNEY' : 'Join the Shwtym Community'}
          </Button>
        </Box>
        {error && <Typography color="error">{error}</Typography>}
        <Typography variant="body2" className="switch-auth-text">
          {isSignIn ? "Not a member yet? " : "Already a member? "}
          <span onClick={toggleAuthMode} className="switch-auth-link">
            {isSignIn ? 'Join the Shwtym Community' : 'Sign In'}
          </span>
        </Typography>
      </Box>
    </div>
  );
};

export default WelcomePage;