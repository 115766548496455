import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const FloatingActionButton = ({ onClick }) => {
  return (
    <Fab
      color="primary"
      aria-label="add"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: (theme) => theme.zIndex.speedDial,
      }}
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  );
};

export default FloatingActionButton;