import React from 'react';
import { Card, CardContent, CardActions, Typography, Avatar, Box, IconButton, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from '@mui/icons-material/Share';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const UserInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  zIndex: 1,
  position: 'relative',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.info.light,
  },
}));

const UserInfoText = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const PostContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    backgroundColor: theme.palette.divider,
  },
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  flex: 1,
  borderRadius: 0,
  padding: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
  },
}));

const ActionBar = styled(CardActions)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.05)',
  position: 'relative',
  zIndex: 1,
}));

const PostTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.1rem',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
}));

const MarkdownContent = styled(ReactMarkdown)(({ theme }) => ({
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  fontSize: '0.875rem',
  fontWeight: 300,
  lineHeight: 1.6,
  color: theme.palette.text.secondary,
  textAlign: 'left',
  '& p': {
    marginBottom: theme.spacing(1.5),
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  '& h1': { fontSize: '1.5rem' },
  '& h2': { fontSize: '1.3rem' },
  '& h3': { fontSize: '1.2rem' },
  '& h4': { fontSize: '1.1rem' },
  '& h5': { fontSize: '1rem' },
  '& h6': { fontSize: '0.9rem' },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  '& code': {
    backgroundColor: theme.palette.grey[100],
    padding: '2px 4px',
    borderRadius: 4,
    fontSize: '0.8rem',
  },
  '& pre': {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
    '& code': {
      backgroundColor: 'transparent',
      padding: 0,
    },
  },
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(1, 0),
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
  },
  '& ul, & ol': {
    paddingLeft: theme.spacing(3),
  },
  '& strong': {
    fontWeight: 500,
  },
}));

const PostCard = ({ post, user, currentUser, onEditPost }) => {
  if (!user) {
    return null;
  }

  const formatContent = (content) => {
    return content.replace(/@(\w+)/g, '[$&](/profile/$1)');
  };

  const isOwnPost = currentUser && currentUser.uid === post.userId;

  return (
    <StyledCard>
      <Link
        component={RouterLink}
        to={`/profile/${post.userId}`}
        underline="none"
        color="inherit"
      >
        <UserInfo>
          <Avatar src={user.profilePicUrl} sx={{ width: 40, height: 40, mr: 2 }} />
          <UserInfoText sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              @{user.username}
            </Typography>
          </UserInfoText>
          {isOwnPost && (
            <IconButton onClick={(e) => { e.preventDefault(); onEditPost(post); }} size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </UserInfo>
      </Link>
      <PostContent>
        <PostTitle variant="h6" align="left">
          {post.title}
        </PostTitle>
        <MarkdownContent remarkPlugins={[remarkGfm]}>
          {formatContent(post.content)}
        </MarkdownContent>
      </PostContent>
      <ActionBar disableSpacing>
        <ActionButton aria-label="like">
          <ThumbUpIcon />
        </ActionButton>
        <ActionButton aria-label="comment">
          <CommentIcon />
        </ActionButton>
        <ActionButton aria-label="share">
          <ShareIcon />
        </ActionButton>
        <ActionButton aria-label="save">
          <BookmarkIcon />
        </ActionButton>
      </ActionBar>
    </StyledCard>
  );
};

export default PostCard;