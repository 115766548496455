import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { collection, query, orderBy, limit, startAfter, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import PostCard from '../Post/PostCard';
import { AuthContext } from '../Auth/AuthContext';
import EditPostDialog from '../Common/EditPostDialog';

const POSTS_PER_PAGE = 10;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const FeedPage = () => {
  const [posts, setPosts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [openEditPost, setOpenEditPost] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const { user: currentUser } = useContext(AuthContext);

  const getCachedData = (key) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedData = (key, data) => {
    localStorage.setItem(key, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  };

  const fetchUserData = async (userId) => {
    const cachedUser = getCachedData(`user_${userId}`);
    if (cachedUser) return cachedUser;

    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setCachedData(`user_${userId}`, userData);
      return userData;
    }
    return null;
  };

  const fetchPosts = useCallback(async (loadMore = false) => {
    if (loading) return;
    setLoading(true);

    try {
      let fetchedPosts;
      if (!loadMore) {
        const cachedPosts = getCachedData('feed_posts');
        if (cachedPosts) {
          setPosts(cachedPosts);
          setLoading(false);
          return;
        }
      }

      const postsRef = collection(db, 'posts');
      let q = query(postsRef, orderBy('createdAt', 'desc'), limit(POSTS_PER_PAGE));

      if (loadMore && lastVisible) {
        q = query(q, startAfter(lastVisible));
      }

      const querySnapshot = await getDocs(q);
      fetchedPosts = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const postData = doc.data();
        const userData = await fetchUserData(postData.userId);
        return {
          id: doc.id,
          ...postData,
          user: userData
        };
      }));

      setPosts(prevPosts => loadMore ? [...prevPosts, ...fetchedPosts] : fetchedPosts);
      
      if (!loadMore) {
        setCachedData('feed_posts', fetchedPosts);
      }

      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  }, [lastVisible, loading]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleEditPost = (post) => {
    setEditingPost(post);
    setOpenEditPost(true);
  };

  const handleCloseEditPost = async (updatedPost) => {
    setOpenEditPost(false);
    if (updatedPost) {
      setPosts(prevPosts =>
        prevPosts.map(post =>
          post.id === updatedPost.id ? { ...post, ...updatedPost } : post
        )
      );
      // Update cache
      const cachedPosts = getCachedData('feed_posts');
      if (cachedPosts) {
        const updatedCachedPosts = cachedPosts.map(post =>
          post.id === updatedPost.id ? { ...post, ...updatedPost } : post
        );
        setCachedData('feed_posts', updatedCachedPosts);
      }
    }
    setEditingPost(null);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', pt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Feed
      </Typography>
      {posts.map(post => (
        <PostCard 
          key={post.id} 
          post={post} 
          user={post.user}
          currentUser={currentUser}
          onEditPost={handleEditPost}
        />
      ))}
      {loading && <CircularProgress sx={{ display: 'block', margin: 'auto' }} />}
      {hasMore && !loading && (
        <Typography 
          variant="body2" 
          color="primary" 
          sx={{ cursor: 'pointer', textAlign: 'center', mt: 2 }}
          onClick={() => fetchPosts(true)}
        >
          Load More
        </Typography>
      )}
      <EditPostDialog 
        open={openEditPost} 
        onClose={handleCloseEditPost} 
        post={editingPost} 
      />
    </Box>
  );
};

export default FeedPage;