import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const NowPlayingPage = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Now Playing
        </Typography>
        <Typography variant="body1">
          Here you'll see movies currently playing in theaters near you.
        </Typography>
      </Box>
    </Container>
  );
};

export default NowPlayingPage;