import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { AuthContext } from '../Auth/AuthContext';

const NewPostDialog = ({ open, onClose }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const { user } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.trim() && content.trim() && user) {
      try {
        await addDoc(collection(db, 'posts'), {
          title: title.trim(),
          content: content.trim(),
          userId: user.uid,
          createdAt: new Date(),
          likes: 0,
          comments: []
        });
        setTitle('');
        setContent('');
        onClose();
      } catch (error) {
        console.error("Error adding new post: ", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create New Post</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            id="content"
            label="Content"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">Post</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewPostDialog;