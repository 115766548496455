import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Typography, Box, Avatar, Chip, IconButton, Fab, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';
import { doc, getDoc, collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PostCard from '../Post/PostCard';
import NewPostDialog from '../Common/NewPostDialog';
import EditPostDialog from '../Common/EditPostDialog';

const POSTS_PER_PAGE = 10;

const ProfilePage = () => {
  const { user: currentUser } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [posts, setPosts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [openNewPost, setOpenNewPost] = useState(false);
  const [openEditPost, setOpenEditPost] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const navigate = useNavigate();
  const { userId } = useParams();

  const fetchProfile = useCallback(async () => {
    const docRef = doc(db, 'users', userId || currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProfile({ id: docSnap.id, ...docSnap.data() });
    }
  }, [userId, currentUser.uid]);

  const fetchPosts = useCallback(async (loadMore = false) => {
    if (loading || (!loadMore && posts.length > 0)) return;

    setLoading(true);
    const postsRef = collection(db, 'posts');
    let q = query(
      postsRef,
      where('userId', '==', userId || currentUser.uid),
      orderBy('createdAt', 'desc'),
      limit(POSTS_PER_PAGE)
    );

    if (loadMore && lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    try {
      const querySnapshot = await getDocs(q);
      const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(prevPosts => loadMore ? [...prevPosts, ...fetchedPosts] : fetchedPosts);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  }, [loading, posts.length, lastVisible, userId, currentUser.uid]);

  useEffect(() => {
    fetchProfile();
    fetchPosts();
  }, [fetchProfile, fetchPosts]);

  const handleNewPost = () => {
    setOpenNewPost(true);
  };

  const handleEditPost = (post) => {
    setEditingPost(post);
    setOpenEditPost(true);
  };

  const handleCloseNewPost = () => {
    setOpenNewPost(false);
    fetchPosts();
  };

  const handleCloseEditPost = (updated) => {
    setOpenEditPost(false);
    setEditingPost(null);
    if (updated) {
      fetchPosts();
    }
  };

  if (!profile) return <Typography>Loading...</Typography>;

  const isOwnProfile = !userId || userId === currentUser.uid;

  const getPronouns = (gender) => {
    switch (gender) {
      case 'Male': return '(him)';
      case 'Female': return '(her)';
      default: return '(them)';
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', pt: 4, textAlign: 'center', position: 'relative' }}>
      {isOwnProfile && (
        <IconButton
          onClick={() => navigate('/edit-profile')}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <EditIcon />
        </IconButton>
      )}
      <Avatar
        src={profile.profilePicUrl}
        sx={{ width: 200, height: 200, margin: 'auto', mb: 2 }}
      />
      <Typography variant="h4" gutterBottom>
        {`${profile.firstName} ${profile.lastName} ${getPronouns(profile.gender)}`}
      </Typography>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        @{profile.username}
      </Typography>
      {profile.location && (
        <Chip
          icon={<LocationOnIcon />}
          label={profile.location}
          variant="outlined"
          sx={{ mt: 1 }}
        />
      )}
      
      {isOwnProfile && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', fontSize: '0.8rem', color: 'text.secondary' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <EmailIcon fontSize="small" sx={{ mr: 0.5 }} />
            <Typography variant="body2">{profile.email}</Typography>
          </Box>
          {profile.dateOfBirth && (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <CakeIcon fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2">{profile.dateOfBirth}</Typography>
            </Box>
          )}
          {profile.phone && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2">{profile.phone}</Typography>
            </Box>
          )}
        </Box>
      )}
      
      <Box sx={{ mt: 4 }}>
        {posts.map(post => (
          <PostCard 
            key={post.id} 
            post={post} 
            user={profile} 
            currentUser={currentUser}
            onEditPost={handleEditPost}
          />
        ))}
        {hasMore && (
          <Button onClick={() => fetchPosts(true)} disabled={loading}>
            {loading ? 'Loading...' : 'Load More Posts'}
          </Button>
        )}
      </Box>

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={handleNewPost}
      >
        <AddIcon />
      </Fab>

      <NewPostDialog open={openNewPost} onClose={handleCloseNewPost} />
      <EditPostDialog open={openEditPost} onClose={handleCloseEditPost} post={editingPost} />
    </Box>
  );
};

export default ProfilePage;